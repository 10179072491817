@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 30 2% 22%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 7 93% 67%;
    --primary-foreground: 0 0% 100%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    user-select: none;
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.audio-slider {
  @apply appearance-none w-full h-0.5 bg-transparent;
}

.audio-slider::-webkit-slider-runnable-track {
  @apply appearance-none bg-transparent;
}

.audio-slider::-moz-range-track {
  background: transparent;
}

.audio-slider::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.audio-slider::-webkit-slider-thumb {
  @apply appearance-none w-2 h-2 bg-primary rounded-full ring-2 ring-white;
  cursor: pointer;
}

.audio-slider::-moz-range-thumb {
  @apply w-2 h-2 bg-primary rounded-full ring-2 ring-white;
  cursor: pointer;
}

.audio-slider::-ms-thumb {
  @apply w-2 h-2 bg-primary rounded-full ring-2 ring-white;
  cursor: pointer;
}

.audio-slider::-ms-fill-lower {
  background: transparent;
}

.audio-slider::-ms-fill-upper {
  background: transparent;
}

.audio-slider-lg {
  @apply audio-slider h-1;
}

.audio-slider-lg::-webkit-slider-thumb {
  @apply w-3 h-3;
}

.audio-slider-lg::-moz-range-thumb {
  @apply w-3 h-3;
}

.audio-slider-lg::-ms-thumb {
  @apply w-3 h-3;
}

.course-pricing {
  ul {
    @apply space-y-5;

    li {
      @apply before:w-5 before:h-5 before:bg-[url("/png/checked-square.png")] before:bg-no-repeat before:bg-contain before:bg-center pl-9 relative before:left-0 before:block before:absolute;
    }

    li,
    span,
    a {
      @apply text-[#393837] text-sm;
    }
  }
}

.tailwind-reset {
  * {
    user-select: none;
  }
  a {
    @apply text-blue-700 underline;
  }
  p {
    @apply my-4;
  }

  blockquote,
  figure {
    @apply my-4 mx-10;
  }

  hr {
    @apply border;
  }

  h1 {
    @apply text-4xl font-bold my-2;
  }

  h2 {
    @apply text-2xl font-bold my-3;
  }

  h3 {
    @apply text-lg font-bold my-4;
  }

  h4 {
    @apply text-base font-bold my-5;
  }

  h5 {
    @apply text-sm font-bold my-6;
  }

  h6 {
    @apply text-xs font-bold my-10;
  }

  article,
  aside,
  nav,
  section {
    h1 {
      @apply text-2xl font-bold my-3;
    }

    article,
    aside,
    nav,
    section {
      h1 {
        @apply text-lg font-bold my-4;
      }

      article,
      aside,
      nav,
      section {
        h1 {
          @apply text-base font-bold my-5;
        }

        article,
        aside,
        nav,
        section {
          h1 {
            @apply text-sm font-bold my-6;
          }

          article,
          aside,
          nav,
          section {
            h1 {
              @apply text-xs font-bold my-10;
            }
          }
        }
      }
    }
  }

  ul,
  menu {
    @apply list-disc my-1 pl-10;
  }

  ol {
    @apply list-decimal my-4 pl-10;
  }

  ul,
  ol {
    ul {
      list-style-type: circle;
    }

    ul,
    ol {
      ul {
        list-style-type: square;
      }
    }
  }

  dd {
    @apply pl-10;
  }

  dl {
    @apply my-4;
  }

  ul,
  ol,
  menu,
  dl {
    ul,
    ol,
    menu,
    dl {
      margin: 0;
    }
  }

  legend {
    @apply py-0 px-1;
  }

  fieldset {
    @apply my-0 mx-1 pt-0 px-1 pb-2;
  }

  b,
  strong {
    font-weight: bold;
  }

  pre {
    @apply my-4;
  }

  img {
    width: 100%;
    border-radius: 8px;
  }

  img,
  video {
    max-width: 100%;
  }
}
